import React, { Component } from 'react';

import './App.scss';
import MqttService from './utils/mqttService';
import { Sidebar } from './components/sidebar/Sidebar';
import DashBoardPage from './components/pages/dashboard/DashBoardPage';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginPage from './components/pages/login/LoginPage';
import { merge } from 'lodash';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          pronto: {},
          isLoggedIn: false,
        }

        this.loginClicked = this.loginClicked.bind(this);
        this.loginSuccessCallback = this.loginSuccessCallback.bind(this);
        this.handleProntoData = this.handleProntoData.bind(this);
    }


    componentDidMount() {
        this.mqttService = new MqttService();
        window.addEventListener('mqttEvent', (e) => {
            //console.log(e);
            console.log('^^^: App -> componentDidMount -> e.detail', e.detail);
            const data = e.detail.pronto

            this.handleProntoData(data);
        });

        window.addEventListener('commandEvent', (e) => {
            console.log('^^^: App -> componentDidMount -> e.detail', e.detail);
            this.mqttService.publish(e.detail.topic);
        });
    }

    loginSuccessCallback(loginSuccessful) {
        if (loginSuccessful) {
            this.setState({ isLoggedIn: true });
            this.mqttService.subscribe("pronto/+/status");
            this.mqttService.subscribe("pronto/+/color");
            this.mqttService.subscribe("pronto/+/components/#");
        }
    }

    loginClicked(username, password) {
        this.mqttService.login(username, password, this.loginSuccessCallback);
    }

    handleProntoData(data) {
      const stateData = this.state.pronto;

      console.log("--- Old Data: --->", stateData);
      merge(stateData, data);
      console.log("--- New Data: --->", stateData);
      this.setState({ data: stateData })
    }

    render() {
        if (!this.state.isLoggedIn) {
            return <LoginPage loginClicked={this.loginClicked} />;
        }
        return (
            <Router basename="/">
                <div className="App">
                    <Sidebar />
                    <Route exact path="/">
                        <DashBoardPage data={this.state.data}></DashBoardPage>
                    </Route>
                    <Route exact path="/dashboard">
                        <DashBoardPage data={this.state.data}></DashBoardPage>
                    </Route>
                    <Route exact path="/connections">
                        Connections
                    </Route>
                </div>
            </Router>
        );
    }
}

export default App;
