import React from 'react';
import './connecteditem.scss';
import ProntoClient from '../../models/ProntoClient';
import ProntoComponent from '../../models/ProntoComponent';

const ConnectedComponent = (props: { [elementName: string]: ProntoComponent }) => {

  const components: ProntoComponent[] = []

  for (const key in props) {
    if (props.hasOwnProperty(key)) {
      const element = props[key];
      components.push(element);
    }
  }

  return (
    <ul>{components.map(component => {
      return (
        <li className="component" key={component.title}>
          <div className="valueTitle">{component.title}</div>
          <div className="value">{component.value.toString()}</div>
        </li>);
  })}</ul>);
}

export const ConnectedItem = (props: ProntoClient) => {
    const { name, color, status, components } = props;
    const statusString = status ? status.status : 'offline';
    const rgb = (color && (statusString === 'online')) ? 'rgb(' + color.red + ', ' + color.green + ', ' + color.blue + ')' : 'grey';
    return (
      <div style={{borderStyle: 'solid', borderColor: rgb}} className={"connectedItemRoot roundedBoxWithShadow " + statusString}>
          <h5 className="title">{name}</h5>
          <ConnectedComponent {...components}/>
        </div>
    );
};
